.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .invoice-company-info {
    text-align: left;
  }
  
  .invoice-statement-info {
    text-align: right;
  }
  
  .summary-table,
  .transactions-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .summary-table td,
  .transactions-table th,
  .transactions-table td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
  
  .transactions-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .transactions-table td {
    text-align: left;
  }
  
  .print-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .statement-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .summary-item {
    text-align: center;
  }
  
  .summary-item .value {
    font-size: 18px;
    font-weight: bold;
  }
  
  .summary-item .label {
    font-size: 12px;
    color: gray;
  }
  