.detailed-statement {
  padding: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.statement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.company-logo {
  width: 100px;
  height: auto;
}

.company-details {
  text-align: right;
}

.company-details p {
  margin: 2px 0;
  font-size: 14px;
  color: #555;
}

.statement-title {
  text-align: center;
  margin-bottom: 30px;
}

.statement-title h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  color: #007bff;
}

.statement-title p {
  font-size: 16px;
  color: #888;
}

.account-summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.account-summary h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.summary-row:last-child {
  border-bottom: none;
}

.summary-row span {
  font-size: 18px;
  color: #333;
}

.summary-row .value {
  font-weight: bold;
}

.transactions-table {
  margin-bottom: 40px;
}

.transactions-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

.transactions-table th,
.transactions-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.transactions-table th {
  background-color: #007bff;
  color: white;
  font-weight: 600;
}

.transactions-table td {
  color: #555;
}

.transactions-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transactions-table tfoot tr {
  background-color: #e9ecef;
  font-weight: 600;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-buttons .print-button,
.modal-buttons .download-button,
.modal-buttons .close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.modal-buttons .print-button:hover,
.modal-buttons .download-button:hover,
.modal-buttons .close-button:hover {
  background-color: #0056b3;
}
