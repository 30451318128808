/* src/styles/AdminComponents.css */

.admin-container {
    background-color: #5EB9BE;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
    color: white;
    text-align: center;
  }
  
  .admin-container h1 {
    color: white;
    font-size: 2em;
  }
  
  .admin-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .admin-container input,
  .admin-container select,
  .admin-container button,
  .admin-container textarea {
    padding: 10px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
  }
  
  .admin-container input,
  .admin-container select,
  .admin-container textarea {
    background-color: white;
    color: #333;
  }
  
  .admin-container button {
    background-color: #D4C381;
    color: #333;
    cursor: pointer;
  }
  
  .admin-container button:hover {
    background-color: #b3a86c;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .admin-container {
      padding: 10px;
      margin: 10px;
    }
  
    .admin-container h1 {
      font-size: 1.5em;
    }
  
    .admin-container form {
      gap: 5px;
    }
  
    .admin-container input,
    .admin-container select,
    .admin-container button,
    .admin-container textarea {
      padding: 8px;
      font-size: 14px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .admin-container {
      padding: 15px;
      margin: 15px;
    }
  
    .admin-container h1 {
      font-size: 1.75em;
    }
  
    .admin-container form {
      gap: 8px;
    }
  
    .admin-container input,
    .admin-container select,
    .admin-container button,
    .admin-container textarea {
      padding: 9px;
      font-size: 15px;
    }
  }
  
  @media (min-width: 1025px) {
    .admin-container {
      padding: 20px;
      margin: 20px auto;
    }
  
    .admin-container h1 {
      font-size: 2em;
    }
  
    .admin-container form {
      gap: 10px;
    }
  
    .admin-container input,
    .admin-container select,
    .admin-container button,
    .admin-container textarea {
      padding: 10px;
      font-size: 16px;
    }
  }
  