/* src/components/ManageOrders.css */

.manage-orders {
    background-color: #5EB9BE;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
    color: white;
  }
  
  .manage-orders h1 {
    text-align: center;
    color: white;
  }
  
  .order-card {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    margin: 10px 0;
    color: #333;
  }
  
  .order-card h2 {
    color: #5EB9BE;
  }
  