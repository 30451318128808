/* src/components/NotAuthorized.css */

.not-authorized {
    background-color: #5EB9BE;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
    color: white;
    text-align: center;
  }
  
  .not-authorized h1 {
    color: white;
  }
  
  .not-authorized p {
    color: white;
  }
  