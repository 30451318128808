 /* src/styles/ProductDetail.css */

.product-detail-container {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
    /* background-color: #ffffff; */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.product-detail-image {
    width: 50%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.product-detail-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-detail-info h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333333;
    font-weight: 700;
}

.product-detail-info p {
    font-size: 18px;
    margin-bottom: 15px;
    color: #222222;
    line-height: 1.6;
}

.product-detail-price {
    font-size: 28px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 20px;
}

.product-detail-weight {
    font-size: 18px;
    font-weight: 500;
    color: #777777;
    margin-bottom: 20px;
}

.product-detail-description {
    font-size: 16px;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .product-detail-container {
        flex-direction: column;
        padding: 20px;
    }

    .product-detail-image,
    .product-detail-info {
        width: 100%;
    }

    .product-detail-info h1 {
        font-size: 28px;
    }

    .product-detail-price {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .product-detail-container {
        padding: 10px;
    }

    .product-detail-info h1 {
        font-size: 24px;
    }

    .product-detail-price {
        font-size: 20px;
    }
}

  
  



  
  .full-page-wrapper {
    display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
    font-family: Arial, sans-serif;
    background-image: url('../images/background2.jpg') !important; /* Path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
  }

  
  
  @media (max-width: 768px) {
    .full-page-wrapper {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .full-page-wrapper {
      padding: 5px;
    }
  }

  