/* src/styles/AddClient.css */

.add-client-container {
  background-color: #5EB9BE;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-client-container form {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.add-client-container button {
  background-color: #D4C381;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #4aa7b2;
}
