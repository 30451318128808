/* src/styles/ManageProducts.css */

.manage-products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex: 1 1 calc(33.333% - 40px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eaeaea;
}

.placeholder-image {
  width: 100%;
  height: 200px;
  background-color: #cccccc;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eaeaea;
}

.product-details {
  padding: 20px;
  flex-grow: 1;
}

.product-details h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

.product-details p {
  margin-bottom: 10px;
  color: #666;
}

.product-actions {
  padding: 20px;
  text-align: center;
}

.product-card .edit-button,
.product-card .delete-button {
  background-color: #D4C381;
  color: #333;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  margin: 5px;
  transition: background-color 0.3s;
}

.product-card .edit-button:hover,
.product-card .delete-button:hover {
  background-color: #b3a86c;
}

.product-card .delete-button {
  background-color: #e57373;
}

.product-card .delete-button:hover {
  background-color: #d32f2f;
}

/* Add styles for the filter buttons */
.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-buttons button {
  background-color: #5EB9BE;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-buttons button:hover {
  background-color: #4BA6AA;
}

.filter-buttons button.active {
  background-color: #D4C381;
  color: #333;
}

.product-card.inactive-product {
  opacity: 0.6;
}

/* src/styles/ManageProducts.css */

.weight-input-container {
  display: flex;
  gap: 10px;
}

.unit-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
}
