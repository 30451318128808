.profile-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  .profile-details .form-group {
    margin-bottom: 15px;
  }
  
  .profile-details .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .profile-details .form-group p {
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 10px;
  }
  
  .profile-details .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .profile-details .edit-button,
  .profile-details .save-button,
  .change-password-button {
    background-color: #5EB9BE;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .profile-details .edit-button:hover,
  .profile-details .save-button:hover,
  .change-password-button:hover {
    background-color: #4aa6ac;
  }
  
  .change-password {
    text-align: right;
  }
  
  .submit-button {
    background-color: #5EB9BE;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #4aa6ac;
  }
  
  .modal-content .form-group input {
    width: calc(100% - 20px);
  }
  