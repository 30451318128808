.invoice {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .company-logo {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .company-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .invoice-details {
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .invoice-section {
    margin-bottom: 10px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  
  .invoice-section h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .invoice-items {
    margin-bottom: 20px;
  }
  
  .items-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .items-table th, .items-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .items-table th {
    /* background: #f2f2f2; */
    text-align: left;
  }
  
  .invoice-total {
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .invoice-actions {
    text-align: center;
    margin-top: 20px;
  }
  
  .invoice-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background: #5cb85c;
    color: #fff;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .invoice-actions button:hover {
    background: #4cae4c;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    #invoice, #invoice * {
      visibility: visible;
    }
    #invoice {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: auto;
      border: none;
      box-shadow: none;
      width: 400px;
    }
  }
  