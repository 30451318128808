/* src/styles/Clients.css */

.search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .clients-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .client-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: calc(33.333% - 20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-decoration: none;
    color: #333;
  }
  
  .client-card:hover {
    transform: translateY(-5px);
  }
  
  .client-card h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #5EB9BE;
  }
  
  .client-card p {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
  }
  
  .client-card p strong {
    color: #333;
  }
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .client-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .client-card {
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .sidebar {
      width: 200px;
    }
  
    .sidebar ul {
      display: flex;
      flex-direction: column;
    }
  
    .sidebar ul li {
      margin: 10px 0;
    }
  
    .sidebar ul li a {
      font-size: 16px;
    }
  
    .dashboard-content {
      margin-left: 0;
      padding: 10px;
    }
  
    .client-card {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  