

  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    padding: 23px;
  }
  
  .product-item {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .product-details {
    text-align: center;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quantity-input {
    width: 50px;
    text-align: center;
    margin: 0 10px;
    font-size: 1rem;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .quantity-control button {
    background-color: #ff6f61;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .quantity-control button:hover {
    background-color: #ff4e3a;
  }
  
  .dashboard-content {
    padding: 53px;
  }
  
  form {
    max-width: 800px;
    margin: auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .react-select-container {
    width: 100%;
  }
  
  button[type="submit"] {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  