@media print {
    /* Hide everything except the detailed statement */
    body * {
      visibility: hidden;
    }
  
    .detailed-statement, .detailed-statement * {
      visibility: visible;
    }
  
    .detailed-statement {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  
    .print-button, .download-button, .close-button {
      display: none;
    }
  }
  