.admin-dashboard {
  display: flex;
  transition: margin-left 0.3s;
  overflow-x: hidden;
  padding-top: 50px; /* Adjust padding to accommodate the fixed navbar */
}

.sidebar {
  width: 250px;
  background-color: #5EB9BE;
  padding: 20px;
  color: white;
  height: 100vh;
  position: fixed;
  top: 50px; /* Adjust to accommodate the fixed navbar */
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  margin-top: 5px;
  border-radius: 0 90px;
}

.sidebar h2 {
  color: white;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.sidebar ul li a:hover {
  color: #D4C381;
}

.sidebar ul li a svg {
  margin-right: 10px;
}

.dashboard-content {
  margin-left: 270px;
  padding: 53px;
  flex: 1;
  transition: margin-left 0.3s;
  padding-top: 80px !important;
}

.dashboard-content .toggle-sidebar {
  background: none;
  border: none;
  color: #5EB9BE;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 20px;
  z-index: 1000 !important;
  position: fixed;
  padding-left: 5px;
}

.dashboard-content h1 {
  margin-bottom: 20px;
  padding-left: 10px;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  flex: 1;
  min-width: 200px;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.card a {
  text-decoration: none;
  color: #5EB9BE;
}

.card a:hover {
  color: #D4C381;
}

.card h3 {
  margin-top: 10px;
}

.sidebar-closed .sidebar {
  transform: translateX(-100%);
}

.sidebar-closed .dashboard-content {
  margin-left: 20px;
  padding-top: 80px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .sidebar-closed .sidebar {
    width: 200px;
    transform: translateX(-100%);
  }

  .sidebar-closed .dashboard-content {
    margin-left: 70px;
  }

  .dashboard-content {
    margin-left: 220px;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 140px;
    position: fixed !important;
    padding-top: 30px;
  }

  .sidebar ul {
    display: flex;
    flex-direction: column;
  }

  .sidebar ul li {
    margin: 10px 0;
  }

  .sidebar ul li a {
    font-size: 16px;
  }

  .dashboard-content {
    margin-left: 0;
    padding: 10px;
  }

  .card-grid {
    display: block;
  }

  .card {
    margin-bottom: 20px;
  }

  .sidebar-closed .sidebar {
    display: none;
  }

  .sidebar-closed .dashboard-content {
    margin-left: 0;
    padding-top: 80px;
  }
}

@media (max-width: 400px) {
  .sidebar ul {
    flex-direction: column;
  }

  .sidebar ul li {
    margin: 10px 0;
  }

  .sidebar ul li a {
    font-size: 14px;
  }

  .dashboard-content {
    margin-left: 0;
    padding: 10px;
  }

  .card-grid {
    display: block;
  }

  .card {
    margin-bottom: 20px;
  }

  .sidebar-closed .sidebar {
    display: none;
  }

  .sidebar-closed .dashboard-content {
    margin-left: 0;
  }
}
