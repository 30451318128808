.navbar {
    background-color: #5EB9BE;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 20px; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-title {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    font-family: monospace;
    font-size: 1.2rem;
  }
  
  .navbar .toggle-sidebar {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  