.order-detail {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.detail-row .form-group {
    flex: 1;
    margin-right: 10px;
}

.form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.detail-value {
    font-size: 16px;
    margin-bottom: 10px;
}

.input-field,
.select-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.save-button,
.edit-button,
.print-button {
    background-color: #5EB9BE;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
}

.save-button:hover,
.edit-button:hover,
.print-button:hover {
    background-color: #3b9297;
}

.items-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.items-table th,
.items-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.items-table th {
    background-color: #5EB9BE;
    color: white;
}

.items-table td {
    background-color: #f9f9f9;
}

.buttons {
    margin-top: 20px;
}

h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 24px;
    color: #5EB9BE;
    border-bottom: 2px solid #5EB9BE;
    padding-bottom: 5px;
}

.save-button,
.edit-button,
.print-button {
    background-color: #5EB9BE;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
}

.save-button:hover,
.edit-button:hover,
.print-button:hover {
    background-color: #3b9297;
}

.save-button svg,
.edit-button svg,
.print-button svg {
    margin-right: 5px;
}

input,
select {
    font-size: 16px;
    width: calc(100% - 20px);
}

/* Print Styles */
@media print {
    .sidebar,
    .navbar,
    .buttons {
        display: none;
    }
    .order-detail {
        box-shadow: none;
        padding: 0;
        margin: 0;
    }
    .dashboard-content {
        padding: 0;
    }
}

/* src/styles/OrderDetail.css */

.sales-note {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .sales-note h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .note-textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
  }
  
  .note-textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  