.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login h1 {
  margin-bottom: 20px;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login input {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-container {
  position: relative;
}

/* .password-container input {
  width: 100%;
} */

.show-password-toggle {
  position: absolute;
  right: 10px;
  top: 35%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.show-password-toggle:hover {
  color: #0056b3;
}

button[type="submit"] {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 15px;
}
