/* src/components/AddProduct.css */

.add-product-container {
  background-color: #5EB9BE;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;
  color: white;
  text-align: center;
}

.add-product-container h1 {
  color: white;
}

.add-product-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 20px;
}

.add-product-container .form-group {
  text-align: left;
}

.add-product-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.add-product-container input,
.add-product-container textarea,
.add-product-container button {
  padding: 10px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
}

.add-product-container input,
.add-product-container textarea {
  background-color: white;
  color: #333;
}

.add-product-container button {
  background-color: #D4C381;
  color: #333;
  cursor: pointer;
}

.add-product-container button:hover {
  background-color: #b3a86c;
}

/* src/styles/AddProduct.css */

.weight-input-container {
  display: flex;
  gap: 10px;
}

.unit-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
}
