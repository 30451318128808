.sales-dashboard .dashboard-content h1 {
    margin-bottom: 20px;
    /* padding-top: 60px; */
  }
  
  .sales-dashboard .orders-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .sales-dashboard .order-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    flex: 1;
    min-width: 200px;
    transition: transform 0.3s;
  }
  
  .sales-dashboard .order-card:hover {
    transform: scale(1.05);
  }
  
  .sales-dashboard .order-card h2 {
    margin-bottom: 10px;
  }
  
  .sales-dashboard .order-card p {
    margin: 5px 0;
  }
  
  .sales-dashboard .order-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sales-dashboard .order-card li {
    margin-bottom: 10px;
  }
  
  /* src/styles/ShowOrders.css */

/* Style for the filter section */
.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .filter {
    display: flex;
    align-items: center;
  }
  
  .filter label {
    margin-right: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .select-filter {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .custom-date-filter {
    display: flex;
    align-items: center;
  }
  
  .custom-date-filter label {
    margin: 0 10px;
    font-weight: bold;
    color: #333;
  }
  
  .date-input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Order card style */
  .order-card {
    display: block;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-decoration: none;
    color: #333;
  }
  
  .order-card:hover {
    transform: translateY(-5px);
  }
  
  .order-card h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #5EB9BE;
  }
  
  .order-card p {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
  }
  
  .order-card p strong {
    color: #333;
  }

  /* Delete icon style for the order card */
.delete-icon-order {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent !important;
    border: none;
    color: #e74c3c !important;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .delete-icon-order:hover {
    color: #c0392b !important;
    background-color: #5EB9BE !important;
  }
  
  .order-card {
    position: relative;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-decoration: none;
    color: #333;
  }
  
  .order-card:hover {
    transform: translateY(-5px);
  }
  
  .order-link {
    text-decoration: none;
    color: inherit;
  }
  
  .order-card h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #5EB9BE;
  }
  
  .order-card p {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
  }
  
  .order-card p strong {
    color: #333;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .confirm-button {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #7f8c8d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #c0392b;
  }
  
  .cancel-button:hover {
    background-color: #95a5a6;
  }
  
  