.invoices-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .invoice-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    flex: 1;
    min-width: 200px;
    transition: transform 0.3s;
  }
  
  .invoice-card:hover {
    transform: scale(1.05);
  }
  
  .invoice-card h2 {
    margin-bottom: 10px;
  }
  
  .invoice-card p {
    margin: 5px 0;
  }
  