/* src/components/ManageUsers.css */

.manage-users-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.user-card {
  background-color: #5EB9BE;
  padding: 20px;
  border-radius: 8px;
  color: white;
  flex: 1 1 calc(33.333% - 40px);
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-card h2 {
  margin-bottom: 10px;
}

.user-card p {
  margin-bottom: 10px;
}

.user-card .edit-button,
.user-card .delete-button {
  background-color: #D4C381;
  color: #333;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  margin: 5px;
  transition: background-color 0.3s;
}

.user-card .edit-button:hover,
.user-card .delete-button:hover {
  background-color: #b3a86c;
}

.user-card .delete-button {
  background-color: #e57373;
}

.user-card .delete-button:hover {
  background-color: #d32f2f;
}

/* Add styles for the filter buttons */
.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-buttons button {
  background-color: #5EB9BE;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-buttons button:hover {
  background-color: #4BA6AA;
}

.filter-buttons button.active {
  background-color: #D4C381;
  color: #333;
}

.user-card.inactive-user {
  opacity: 0.6;
}

/* Add styles for the filter dropdown */
.filter-dropdown {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-dropdown select {
  background-color: #5EB9BE;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-dropdown select:hover {
  background-color: #4BA6AA;
}

.user-card.inactive-user {
  opacity: 0.6;
}
