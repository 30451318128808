/* Global Styles */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f7f9fc;
  margin: 0;
  padding: 0;
  color: #1f2a3c;
}

/* Full Page Wrapper */
.full-page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;
}

h1 {
  font-size: 42px;
  font-weight: 700;
  color: #37474f;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

/* Products Grid */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

/* Product Card */
.product-card {
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  text-align: center;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 180px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 12px;
}

/* Product Name */
h2 {
  font-size: 22px;
  font-weight: 600;
  color: #37474f;
  margin-bottom: 12px;
}

/* Product Price and Weight */
.product-card p {
  font-size: 16px;
  color: #607d8b;
  margin-bottom: 8px;
}

/* View Details Link */
.product-detail-link {
  display: inline-block;
  background-color: #1e88e5;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.product-detail-link:hover {
  background-color: #1565c0;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }

  .product-card {
    padding: 15px;
  }

  .product-detail-link {
    font-size: 14px;
    padding: 10px 20px;
  }
}
