.manage-stock-container {
    margin-top: 20px;
  }
  
  .stock-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .stock-table th, .stock-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .stock-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .add-quantity-button, .update-quantity-button {
    background-color: #5EB9BE;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
  }
  
  .add-quantity-button:hover, .update-quantity-button:hover {
    background-color: #3b9297;
  }
  
  .update-stock-button {
    background-color: #5EB9BE;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .update-stock-button:hover {
    background-color: #3b9297;
  }
  
  .modal input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  