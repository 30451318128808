  
  .sales-users-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .sales-user-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: #34495e;
    width: calc(33.333% - 20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .sales-user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .sales-user-card h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .sales-user-card p {
    margin-bottom: 10px;
    font-size: 16px;
    color: #7f8c8d;
  }
  
  @media (max-width: 768px) {
    .sales-user-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .sales-user-card {
      width: 100%;
    }
  }
  